<template>
  <div class="order_detail">
    <div class="basic_info">
      <div class="title">基本信息</div>
      <div class="info_box">
        <div class="item">订单编号：<span class="value">{{detail.orderNumber}}</span><span @click="openDetail()" style="color: #3d7fff;cursor: pointer;margin-left: 10px;">详情</span></div>
        <div class="item">下单时间：<span class="value">{{detail.createTime}}</span></div>
        <div class="item">服务名称：<span class="value">{{detail.goodsName}}</span></div>
        <div class="item">服务订单号：<span class="value" v-if="detail.serviceOrderList">{{detail.serviceOrderList[0].serverNumber}}</span></div>
        <div class="item">服务次数：<span class="value">{{detail.useNumber}}/{{detail.number}}</span></div>
        <div class="item">订单套餐：<span class="value">{{detail.specName}}{{detail.duration? detail.duration+'小时':''}}</span></div>
        <div class="item">支付时间：<span class="value">{{detail.paySuccessTime}}</span></div>
        <div class="item">服务状态：<span class="value">{{status[serviceOrder.status]}}</span></div>
        <div class="item" v-if="startTime">上门时间：<span class="value">{{startTime}}</span></div>
        <div class="item" v-if="detail.remark">订单取消原因：<span class="value">{{detail.remark||''}}</span></div>
      </div>
    </div>

    <div class="user_info">
      <div class="title">用户信息</div>
      <div class="info_box">
        <div class="item">用户姓名：<span class="value">{{serviceOrder.userName}}</span></div>
        <div class="item">手机号码：<span class="value">{{serviceOrder.telNumber}}</span></div>
        <div class="item">用户来源：<span class="value">{{'微信小程序'}}</span></div>
        <div class="item">服务地址：<span class="value">{{serviceOrder.provinceName}}{{serviceOrder.cityName}}{{serviceOrder.countyName}}{{serviceOrder.detailInfo}}</span></div>
      </div>
    </div>

    <div class="staff_info" v-if="hmServiceStaffVo">
      <div class="title">服务人员信息</div>
      <div class="info_box">
        <div class="item">服务人员姓名：
          <span class="value">{{hmServiceStaffVo.name}}</span>
          <span @click="openStaffDetail" style="color: #3d7fff;cursor: pointer;margin-left: 10px;">详情</span>
          <span v-if="(serviceOrder.status===3||serviceOrder.status===4) &&(!settlementDetailInfo.amount || settlementDetailInfo.status===2)" @click="settlement = true" style="color: #3d7fff;cursor: pointer;margin-left: 10px;">结算</span>
        </div>
        <div class="item">手机号码：<span class="value">{{hmServiceStaffVo.phone}}</span></div>
        <div class="item">地址：<span class="value">
          {{hmServiceStaffVo.province||'暂无'}}{{hmServiceStaffVo.city}}{{hmServiceStaffVo.county}}{{hmServiceStaffVo.address}}
        </span></div>
        <div class="item" v-if="settlementDetailInfo.amount">结算金额：<span class="value" style="color: #EB0A0A">{{modalFormSettlement.amount}}元</span></div>
        <div class="item" v-if="serviceOrder.bonusAmount">五星奖金：<span class="value">{{serviceOrder.bonusAmount || '0.00' }} 元</span></div>
      </div>
    </div>
    <div class="settlement_flowList" v-if="settlementDetailInfo.amount">
      <div class="title" style="padding-bottom: 10px;">结算进度</div>
      <ch-table :render-option="settlementFlowOption" :data="settlementFlowList" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:status="scope">
          <div>{{settlementStatus[scope.row.status]||'一'}}</div>
        </template>
      </ch-table>
    </div>
    <div class="serve_info">
      <div class="title">商品信息</div>
      <ch-table :render-option="serveInfoOption" :data="serveInfo" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:amount="scope">
          <div>{{scope.row.amount}} <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" /></div>
        </template>
      </ch-table>
    </div>

    <div class="value_info" v-if="appreciationOrdersList.length>0">
      <div class="title">增购信息</div>
      <div class="table_head">
        <div>增购服务名称</div>
        <div>描述</div>
        <div>计算方式</div>
        <div>数量</div>
        <div>支付时间</div>
        <div>实付金额</div>
        <div>状态</div>
      </div>
      <div v-for="(item,index) in appreciationOrdersList" :key="index">
        <div class="table_body" v-for="(child,childIndex) in item.appreciationOrderDetailList" :key="childIndex" >
          <div>{{child.name}}</div>
          <div>{{child.describe  || '一'}}</div>
          <div>{{child.formula || '一'}}</div>
          <div>{{child.quantity }}</div>
          <div>{{child.createTime }}</div>
          <div>{{child.amount}}元</div>
          <div></div>
        </div>
        <div class="table_and">
          <div style="font-weight: bold;color: #333">增购订单({{index+1}})合计</div>
          <div></div>
          <div></div>
          <div style="font-weight: bold;color: #EB0A0A">{{item.amount}}元</div>
          <div class="refund">{{appreciationOrdersStats[item.status]}}</div>
        </div>
      </div>
    </div>

    <div class="flowList">
      <div class="title" style="padding-bottom: 10px;">服务进度</div>
      <ch-table :render-option="flowOption" :data="flowList" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}" :attribute="attribute"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:type="scope">
          <div>{{serveType[scope.row.type]||'一'}}</div>
        </template>
        <template v-slot:causeImg="scope">
          <div v-if="(scope.row.type===4||scope.row.type===5||scope.row.type===6)&&scope.row.cause" style="color: #3d7fff;cursor: pointer" @click="openImgView(scope.row.cause)">查看</div>
          <div v-else>暂无</div>
        </template>
      </ch-table>
    </div>

    <div class="splitMoneyInfo">
      <div class="title" style="padding-bottom: 10px;">本单结算</div>
      <ch-table :render-option="splitMoneyInfoOption" :data="splitMoneyInfoVoList" class="mt-10" ref="splitMoneyInfo"
                :border="true" :header-cell-style="{background:'#FAFAFA'}" :summary-method="getSummaries" show-summary
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:type="scope">
          <div>{{serveType[scope.row.type]||'一'}}</div>
        </template>
        <template v-slot:refundAmount="scope">
          <div>{{scope.row['_refundAmount']}} </div>
        </template>
        <template v-slot:chAmount="scope">
          <div>{{scope.row['_chAmount']}} </div>
        </template>
        <template v-slot:staffAmount="scope">
          <div>{{scope.row['_staffAmount']}} </div>
        </template>
        <template v-slot:hmAmount="scope">
          <div>{{scope.row['hmAmount']}} </div>
        </template>
        <template v-slot:reward="scope">
          <div>{{scope.row['reward']}} </div>
        </template>
        <template v-slot:causeImg="scope">
          <div v-if="(scope.row.type===4||scope.row.type===5||scope.row.type===6)&&scope.row.cause" style="color: #3d7fff;cursor: pointer" @click="openImgView(scope.row.cause)">查看</div>
          <div v-else>暂无</div>
        </template>
      </ch-table>
    </div>
    <modal ref="modal" />
    <el-dialog title="结算" :visible.sync="settlement" width="500px" :close-on-click-modal="false" @close="handleCloseSettlement">
      <ch-form :render-option="formOptionSettlement" :model="modalFormSettlement" :rules="rulesSettlement"
               :props="{ labelWidth: '120px' , paddingRight: '30px'}"
               ref="modalFormSettlement">
      </ch-form>
      <span slot="footer" class="flex_con">
        <ch-button type="cancel" @click="handleCloseSettlement">取消</ch-button>
        <ch-button type="ok" :loading="loadingSettlement" @click="handleSubmitSettlement">确定</ch-button>
      </span>
    </el-dialog>

    <el-image-viewer
        v-if="showViewer"
        :z-index="9999"
        :on-close="closeViewer"
        :url-list="viewerList" />
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import dayjs from 'dayjs'
import modal from  '@/views/personnelManagement/serviceStaff/modal.vue'
export default {
  components:{ElImageViewer,modal},
  data() {
    return {
      detail:{},
      serveInfo:[],
      serviceOrder:{},
      hmServiceStaffVo:{},
      appreciationOrdersList:[],
      status:['商品订单待支付','待服务','已取消','已评价','已完成','服务中','待验收','待接单','待签到','申诉中','夜间待支付','爽约'],
      serveType: ['一','预约','取消','修改','开始','待验收','已完成','已接单','更换服务人员', '申请改期', '同意改期','拒绝改期','签到'], //cause 5 7
      appreciationOrdersStats: ['待支付', '已取消', '已支付', '已退款中', '已退款'], // 0:待支付,1:已取消,2:已支付,3:已退款中,4:已退款
      flowList:[],
      splitMoneyInfoVoList:[],
      showViewer:false,
      viewerList:[],
      startTime: '',
      settlement: false, // 结算弹窗
      loadingSettlement: false,
      modalFormSettlement: {
        amount: '',
      },
      settlementFlowList:[],
      settlementStatus: ['待审核','通过','不通过'],
      settlementDetailInfo: {},
    }
  },
  created() {
    if(this.$route.query.orderId){
      this.getOrderDetail(this.$route.query.orderId)
    }
  },
  computed: {
    serveInfoOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "服务名称", prop: "goodsName",},
        {column: "text", label: "服务明细", prop: "serveDetail", showOverflowTooltip: true,},
        {column: "text", label: "服务时长", prop: "duration",},
        {column: "cash", label: "订单平均金额", prop: "price",},
        {column: "cash", label: "增购金额", prop: "appreciation"},
        {column: "cash", label: "夜间服务费", prop: "nightServiceFee"},
        {column: "slot", label: "本单金额", slotName: "amount",},
      ]
    },
    tableOption() {
      return [
        {column: "text", label: "交易时间", prop: "createTime",},
        {column: "text", label: "订单号", prop: "orderNumber",},
        {column: "text", label: "服务订单号", prop: "serverNumber"},
        {column: "text", label: "合计金额", prop: "price",},
        {column: "slot", label: "状态", slotName: "status",},
      ];
    },
    flowOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        // {column: "text", label: "服务人员", prop: "createTime",},
        {column: "text", label: "当前进度", prop: "_detail",},
        {column: "slot", label: "订单状态", slotName: "type"},
        {column: "text", label: "操作时间", prop: "createTime",},
        // {column: "text", label: "用户评价", slotName: "status",},
        {column: "slot", label: "服务图片", slotName: "causeImg",},
      ];
    },
    settlementFlowOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "当前进度", prop: "remark",},
        {column: "slot", label: "状态", slotName: "status"},
        {column: "text", label: "操作时间", prop: "createTime",},
      ];
    },
    splitMoneyInfoOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "类型", prop: "_type",},
        {column: "cash", label: "本单金额", prop: "amount",},
        // {column: "cash", label: "扣罚退费", prop: "refundAmount"},
        {column: "slotColumnHeader", label: "扣罚退费", slotName: "refundAmount",prop: "refundAmount", content: '本服务单申诉退费的金额'},
        {column: "slotColumnHeader", label: "平台抽佣", slotName: "chAmount",prop: "chAmount", content: '服务订单抽佣：本单金额*平台分成比例;\n增值订单抽佣：本单金额*微信费率'},
        {column: "slotColumnHeader", label: "服务人员佣金", slotName: "staffAmount", prop: "staffAmount",
          content: '服务人员服务佣金：(本单金额-本单金额*平台分成比例)*服务人员佣金比例;\n服务人员增值佣金：(本单金额-本单金额*微信费率)*增值分成比例;'},
        // {column: "slotColumnHeader", label: "五星奖金", slotName: "reward",prop: "reward",width: '200px', content: '按平台服务流程完成服务单，并获得用户五星好评，将获得现金奖励。服务人员自行到钱包内提现，并承担提现手续费。'},
        {column: "slotColumnHeader", label: "实际收入", slotName: "hmAmount", prop: "hmAmount",content: '本单金额-平台抽佣金额-服务人员佣金金额'},
      ];
    },
    formOptionSettlement: function () {
      return [
        {type: 'number', label: '结算金额：', prop: 'amount', placeholder: "请输入结算金额",precision: 2},
      ]
    },
    rulesSettlement: function (){
      return {
        amount: [
          { required: true, message: '请输入结算金额', trigger: ['blur', 'change'] },
        ],
      }
    }
  },
  methods: {
    getOrderDetail(orderId) {
      this.$curl.get('/hm/order/serviceDetail/'+orderId).then(res => {
        this.detail = res.data
        this.flowList = this.detail.serviceOrderList[0].flowList.map(item => {
          return{
            ...item,
            _detail: item.code ?  `${item.detail} (${item.type===7 ? '开始服务码：':'结束服务码：'}${item.code})` : item.detail,
          }
        })
        this.splitMoneyInfoVoList = this.detail.serviceOrderList[0].splitMoneyInfoVoList.map((item,index)=>{
          return {
            ...item,
            commissionRate:item.commissionRate + '%',
            _type:item.type===1?'服务订单':item.type===2?'增值订单' + `(${index})`:'一',
            _chAmount: item.chAmount + ' (' + item.proportion + '%)',
            _staffAmount: `${item.staffAmount} (${item.commissionRate}%)`,
            reward: item.reward || '0.00',
            _refundAmount: item.appealReason ? item.refundAmount + ' (' + item.appealReason + ')': item.refundAmount,
          }
        })
        const {price,amount,appreciation,startTime} = this.detail?.serviceOrderList[0]
        this.startTime   = startTime // 上门时间
        this.serveInfo = [
          {
            goodsName:this.detail.goodsName,
            price,amount,appreciation,
            duration: this.detail.duration? this.detail.duration+'小时':'一',
            serveDetail: JSON.parse(this.detail.serveDetail).join(),
            payType:this.detail.payType,
            nightServiceFee:this.detail.serviceOrderList[0].nightServiceFee,
          }
        ]
        this.serviceOrder = this.detail.serviceOrderList[0]
        this.hmServiceStaffVo = this.serviceOrder.hmServiceStaffVo
        this.appreciationOrdersList = this.detail.appreciationOrdersList
        if(this.detail.serviceOrderList[0].settlementDetail) {
          this.settlementDetailInfo = this.detail.serviceOrderList[0].settlementDetail
          this.settlementFlowList = this.detail.serviceOrderList[0].settlementDetail.flowList
          this.modalFormSettlement.amount = this.detail.serviceOrderList[0].settlementDetail.amount
        }
      })
    },
    // 合计
    getSummaries(param) {
      this.$nextTick(() => {
        this.$refs.splitMoneyInfo.getTableRef().doLayout();
      })
      // 定义参数接收回调数据
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        let values = [];
        // 使用判断遍历需要计数的列  type
        if (column.property && column.property !== '_type') {
          values = data.map((item) => Number(item[column.property]));
        }
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              // 接口返回与展示单位不一样时需手动计算，防止小数点后相加报错，也可直接 toFixed()
              return (Number(prev * 100 + curr * 100) / 100).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = '';
        }
        // 放在最后防止foreach遍历后不显示自定义名字
        if (index === 1) {
          sums[index] = '合计(元)';
        }
      });
      return sums;
    },
    openImgView(url){
      this.viewerList = url.split(',')
      this.showViewer = true
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    // table 样式问题
    attribute(row) {
      if (row.type === 4&& dayjs(row.createTime)>dayjs(this.startTime)) {
        return {
          style: { color: "#EB0A0A" },
        };
      }
    },
    // 订单详情
    openDetail(){
      this.$router.push({ name: "orderDetail", query: { orderId: this.detail.id } });
    },
    openStaffDetail() {
      this.$refs.modal.openWatch(this.hmServiceStaffVo.id)
    },
    handleCloseSettlement() {
      this.settlement = false
      setTimeout(() => {
        this.modalFormSettlement = {
          amount: '',
        }
      },0)
    },
    // 结算
    handleSubmitSettlement() {
      this.$refs.modalFormSettlement.validate((valid) => {
        if (valid) {
          this.loadingSettlement = true
          this.modalFormSettlement.serviceOrderId = this.$route.query.orderId
          this.$curl.post('/hm/hmServiceOrderSettlement/settlement', this.modalFormSettlement).then((res) => {
            if (res.code === 200) {
              this.$message.success('结算成功')
              this.handleCloseSettlement()
              this.getOrderDetail(this.$route.query.orderId)
            }
          }).finally(() => {
            this.loadingSettlement = false
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.order_detail{
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  margin-top: 15px;
  //overflow: auto;
  // 滚动条
  ::-webkit-scrollbar {
    display: none;
  }
  padding-bottom: 40px;
}

.basic_info,
.serve_info,
.user_info,
.staff_info,
.value_info,
.flowList,
.splitMoneyInfo,
.settlement_flowList{
  padding: 16px 42px;
  background: #FFFFFF;
  .title{
    font-weight: bold;
    padding-bottom: 2px;
  }

}
.basic_info,
.user_info,
.staff_info{
  border-bottom: 1px solid #E8E8E8;
  .info_box{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 33.33%;
      padding-top: 10px;
      font-size: 14px;
      // 换行
      white-space: pre-wrap;
      .value{
        color: #666666;
      }
    }
  }
}

.value_info{
  .table_head{
    font-size: 14px;
    display: flex;
    font-weight: bold;
    line-height: 35px;
    background: #FAFAFA;
    border-radius: 5px 5px 0 0;
    justify-content: space-between;
    padding: 0 32px;
    margin-top: 12px;
    >div{
      width: 33.33%;
    }
    :nth-child(2){
      text-align: center;
    }
    :last-child{
      text-align: right;
    }
  }
  .table_body,
  .table_and{
    color: #666666;
    font-size: 12px;
    display: flex;
    line-height: 35px;
    justify-content: space-between;
    padding: 0 32px 0 32px;
    border: 1px solid #E8E8E8;
    border-top: none;
    >div{
      width: 33.33%;
    }
    :nth-child(2){
      text-align: center;
    }
    :last-child{
      text-align: right;
    }
    .refund{
      color: #3D7FFF;
    }
  }
  .table_and{
    margin-bottom: 10px;
  }
  .table_body:first-child{
    border-top: 1px solid #E8E8E8;
  }
}
.flowList{
  .flowList_warp{
    padding-bottom: 10px;
    .createTime{
      color: #999999;
      padding-top: 10px;
    }
  }
}
div {
  white-space: break-spaces;
}
::v-deep(.el-table td.el-table__cell){
  border-bottom: 1px solid #ebeef5;
}
::v-deep {
  .el-form-item__error {
    left: -30px;
    line-height: 1px;
  }
}
</style>